import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as registrationOpgP1y95a5Meta } from "/app/pages/auth/registration.vue?macro=true";
import { default as contractorscgluBP6UA2Meta } from "/app/pages/contractors.vue?macro=true";
import { default as history2LdGTesqcBMeta } from "/app/pages/history.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as faqCbjQQP23HlMeta } from "/app/pages/profile/faq.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as subscriptionsoIX9jYPDmAMeta } from "/app/pages/profile/subscriptions.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as sSXKnT5H80bMeta } from "/app/pages/s.vue?macro=true";
import { default as tXU8oQDH0gcMeta } from "/app/pages/t.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-registration",
    path: "/auth/registration",
    meta: registrationOpgP1y95a5Meta || {},
    component: () => import("/app/pages/auth/registration.vue").then(m => m.default || m)
  },
  {
    name: "contractors",
    path: "/contractors",
    component: () => import("/app/pages/contractors.vue").then(m => m.default || m)
  },
  {
    name: "history",
    path: "/history",
    meta: history2LdGTesqcBMeta || {},
    component: () => import("/app/pages/history.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name,
    path: "/profile",
    meta: profileupBlgdy3vLMeta || {},
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: "profile-faq",
    path: "faq",
    component: () => import("/app/pages/profile/faq.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "",
    component: () => import("/app/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-subscriptions",
    path: "subscriptions",
    component: () => import("/app/pages/profile/subscriptions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "s",
    path: "/s",
    component: () => import("/app/pages/s.vue").then(m => m.default || m)
  },
  {
    name: "t",
    path: "/t",
    component: () => import("/app/pages/t.vue").then(m => m.default || m)
  }
]