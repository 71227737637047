import { defineNuxtPlugin } from '#app/nuxt'
import { LazySvgoArrowDown, LazySvgoArrowLeft, LazySvgoArrowMenu, LazySvgoCall, LazySvgoCheck, LazySvgoClose, LazySvgoDeleteFile, LazySvgoExit, LazySvgoEyeClosed, LazySvgoEyeOpen, LazySvgoHelp, LazySvgoLogout, LazySvgoPlus, LazySvgoSelectArrow } from '#components'
const lazyGlobalComponents = [
  ["SvgoArrowDown", LazySvgoArrowDown],
["SvgoArrowLeft", LazySvgoArrowLeft],
["SvgoArrowMenu", LazySvgoArrowMenu],
["SvgoCall", LazySvgoCall],
["SvgoCheck", LazySvgoCheck],
["SvgoClose", LazySvgoClose],
["SvgoDeleteFile", LazySvgoDeleteFile],
["SvgoExit", LazySvgoExit],
["SvgoEyeClosed", LazySvgoEyeClosed],
["SvgoEyeOpen", LazySvgoEyeOpen],
["SvgoHelp", LazySvgoHelp],
["SvgoLogout", LazySvgoLogout],
["SvgoPlus", LazySvgoPlus],
["SvgoSelectArrow", LazySvgoSelectArrow],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
