<script setup>
import { useFeedback } from '@/composables/useFeedback';

import UiButton from '@/components/ui/button/UiButton.vue';
import PoliceBlock from '@/components/blocks/PoliceBlock.vue';

const { openFeedbackPopup } = useFeedback();

const props = defineProps({
  isHiddenButtonHelp: {
    type: Boolean,
    default: false,
  },
});
</script>

<template>
  <div class="auth-layout">
    <div class="auth-layout__body container">
      <div class="auth-layout__content">
        <slot name="content" />
      </div>

      <div class="auth-layout__police">
        <PoliceBlock class="auth-layout__links" />

        <UiButton
          v-if="!props.isHiddenButtonHelp"
          class="auth-layout__button auth-layout__button--help hidden-mobile"
          text="Помощь"
          theme="white"
          @click="openFeedbackPopup"
        />

        <UiButton
          v-if="!props.isHiddenButtonHelp"
          class="auth-layout__button auth-layout__button--help hidden-tablet hidden-desktop"
          text="Помощь"
          theme="grey"
          @click="openFeedbackPopup"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.auth-layout {
  $parent: &;

  display: flex;
  padding-top: em(32);
  background-color: $color-yellow-1;

  @include media-breakpoint-down(sm) {
    padding-top: em(24);
  }

  &__content {
    flex-shrink: 0;
    margin: auto;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__button {
    &--help {
      width: 100%;

      @include media-breakpoint-up(sm) {
        position: absolute;
        top: 0;
        left: em(32);
        max-width: em(192);
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: em(16);
  }

  &__links {
    @include media-breakpoint-down(sm) {
      flex-direction: column;
      margin-bottom: em(24);
      color: $color-grey-3;
    }
  }

  &__police {
    flex-shrink: 0;
    align-self: center;
    margin: em(32) 0;

    @include media-breakpoint-down(sm) {
      width: 100%;
      padding: em(24) em(24) em(161);
      margin: em(24) 0 0;
      background-color: $color-white;
      border-radius: em(32) em(32) 0 0;
    }
  }
}
</style>
