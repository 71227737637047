export const analyticNames = {
  gtm: 'gtm',
  ytm: 'ytm',
  mail: 'mail',
};
const scripts = {
  /**
   * Методы для Google Tag Manager (GTM).
   * @type {Object}
   */
  [analyticNames.gtm]: {
    script(gtmID) {
      return `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmID}');`;
    },
    noscript(gtmID) {
      return `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmID}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    },
  },
  /**
   * Методы для Yandex Metrika.
   * @type {Object}
   */
  [analyticNames.ytm]: {
    script(ytmID) {
      return `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();
      for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
      k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
      (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

      ym(${ytmID}, "init", {
      webvisor: true,
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
  });`;
    },
    noscript(ytmID) {
      return `<div><img src="https://mc.yandex.ru/watch/${ytmID}" style="position:absolute; left:-9999px;" alt="" /></div>`;
    },
  },
  [analyticNames.mail]: {
    script(mailID) {
      return `var _tmr = window._tmr || (window._tmr = []);
      _tmr.push({id: "${mailID}", type: "pageView", start: (new Date()).getTime()});
      (function (d, w, id) {
        if (d.getElementById(id)) return;
        var ts = d.createElement("script"); ts.type = "text/javascript"; ts.async = true; ts.id = id;
        ts.src = "https://top-fwz1.mail.ru/js/code.js";
        var f = function () {var s = d.getElementsByTagName("script")[0]; s.parentNode.insertBefore(ts, s);};
        if (w.opera == "[object Opera]") { d.addEventListener("DOMContentLoaded", f, false); } else { f(); }
      })(document, window, "tmr-code");`;
    },
    noscript(mailID) {
      return `<div><img src="https://top-fwz1.mail.ru/counter?id=${mailID};js=na" style="position:absolute;left:-9999px;" alt="http://Top.Mail.Ru " /></div>`;
    },
  },
};

/**
 * Добавляет аналитический скрипт и, если доступно, тег <noscript> в документ.
 * @param {string} analyticName - Название аналитики ('gtm' или 'ytm').
 * @param {number | string} analyticId - Идентификатор аналитики.
 */
function setAnalytic(analyticName, analyticId) {
  const analytic = scripts[analyticName];

  // Если есть внешний источник, добавляем его как <script>
  if (analytic?.externalSrc) {
    const externalScript = document.createElement('script');
    externalScript.src = analytic.externalSrc(analyticId);
    externalScript.async = true;
    document.head.appendChild(externalScript);
  }

  // Добавляем внутренний скрипт
  const script = document.createElement('script');
  script.innerHTML = analytic.script(analyticId);
  document.head.appendChild(script);

  // Добавляем noscript, если доступно
  if (analytic?.noscript) {
    const noscript = document.createElement('noscript');
    noscript.innerHTML = analytic.noscript(analyticId);
    document.body.prepend(noscript);
  }
}

/**
 * Инициализирует Google Tag Manager, если задан GTM ID.
 * @param {number | string} gtmID - Идентификатор GTM.
 */
function initGtmAnalytic(gtmID) {
  if (gtmID) setAnalytic(analyticNames.gtm, gtmID);
}

/**
 * Инициализирует Yandex Metrika, если задан YTM ID.
 * @param {number | string} ytmID - Идентификатор Yandex Metrika.
 */
function initYtmAnalytic(ytmID) {
  if (ytmID) setAnalytic(analyticNames.ytm, ytmID);
}

/**
 * Инициализирует Yandex Metrika, если задан YTM ID.
 * @param {number | string} mailID - Идентификатор Yandex Metrika.
 */
function initMailRuAnalytic(mailID) {
  if (mailID) setAnalytic(analyticNames.mail, mailID);
}

export const useAnalytics = () => {
  return {
    initGtmAnalytic,
    initYtmAnalytic,
    initMailRuAnalytic,
  };
};
