import { defineNuxtPlugin, useHead } from 'nuxt/app';

const getMindBoxTracker = () => {
  return {
    script1: `
    mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
    mindbox.queue = mindbox.queue || [];
    mindbox('create', {
        endpointId: 'discovermoreru'
    });
    `,
    script2: 'https://api.mindbox.ru/scripts/v1/tracker.js',
  };
};

export default defineNuxtPlugin(() => {
  const { script1, script2 } = getMindBoxTracker();

  useHead({
    script: [
      { innerHTML: script1 },
      {
        src: script2,
        async: true,
      },
    ],
  });
});
