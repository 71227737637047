import { defineNuxtPlugin } from '#imports';
import { googleMetricsId } from '@/analytic/google/google-metrics';
import { useAnalytics } from '@/composables/useAnalytics';

const { initGtmAnalytic } = useAnalytics();

export default defineNuxtPlugin(() => {
  if (googleMetricsId) {
    initGtmAnalytic(googleMetricsId);
  }
});
