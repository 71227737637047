<script setup>
import UiLinkTag from '@/components/ui/UiLinkTag.vue';

const props = defineProps({
  to: {
    type: String,
    default: undefined,
  },
  target: {
    type: String,
    default: undefined,
  },
  isStaticByDefault: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    default: undefined,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  theme: {
    type: String,
    default: undefined,
    validator(theme) {
      return ['grey', 'white'].includes(theme);
    },
  },
});

const emit = defineEmits(['click']);

const emitClick = (e) => {
  emit('click', e);
};
</script>
<template>
  <UiLinkTag
    :to="props.to"
    :target="props.target"
    :type="props.type"
    :is-static-by-default="props.isStaticByDefault"
    class="ui-button"
    :is-disabled="isDisabled"
    :class="{
      [`ui-button--theme--${theme}`]: theme,
      'ui-button--disabled': isDisabled,
    }"
    @click="emitClick"
  >
    <slot>
      <span v-if="text" class="ui-button__font">{{ text }}</span>
    </slot>
  </UiLinkTag>
</template>

<style scoped lang="scss">
.ui-button {
  $parent: &;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: em(5) em(36);
  min-height: em(64);
  text-align: center;
  background-color: $color-yellow-2;
  border-radius: em(24);
  transition: $time-default ease-out;
  transition-property: background-color, color;

  @include hover {
    background-color: $color-yellow-3;
  }

  &__font {
    font-size: em(18);
    font-weight: 500;
    line-height: 1;
  }

  &--disabled {
    color: $color-grey-2 !important;
    pointer-events: none;
    touch-action: none;
    background-color: $color-grey-1 !important;
  }

  &--theme {
    &--grey {
      background-color: $color-grey-1;

      @include hover {
        background-color: $color-grey-1;
        color: $color-blue-1;
      }
    }

    &--white {
      background-color: $color-white;

      @include hover {
        background-color: $color-white;
        color: $color-blue-1;
      }
    }
  }
}
</style>
