import { defineStore } from 'pinia';
import { useCookie } from 'nuxt/app';
import { useRequest } from '@/composables/useRequest';

export const useUserStore = defineStore({
  id: 'user',
  state: () => ({
    user: null,
    isSelectedCookie: false,
    isAdult: false,
  }),
  getters: {
    isDeletionRequestStatus: (state) => {
      return state.user?.personalData?.customFields?.deletionRequestStatus;
    },
  },
  actions: {
    fetchUser() {
      return useRequest('/user', {
        method: 'GET',
      })
        .then(({ data }) => {
          this.user = data;

          return data;
        })
        .catch((error) => {
          return error;
        });
    },
    deleteUser() {
      return useRequest('/user/delete', {
        method: 'DELETE',
      }).then(() => {
        this.fetchUser();
      });
    },
    cancelDeleteUser() {
      return useRequest('/user/delete/recall', {
        method: 'POST',
      }).then(() => {
        this.fetchUser();
      });
    },
    clearUserData() {
      this.user = null;
    },
    fetchUserSubscribe(data = {}) {
      return useRequest('/user/subscribe', {
        method: 'POST',
        body: {
          subscriptionSmsCurrent: data.subscriptionSmsCurrent,
          subscriptionEmailCurrent: data.subscriptionEmailCurrent,
          subscriptionSms: data.subscriptionSms,
          subscriptionEmail: data.subscriptionEmail,
        },
      }).then(() => {
        this.fetchUser();
      });
    },
    changeUserPassword(data = {}) {
      return useRequest('/user/password', {
        method: 'POST',
        body: {
          password: data.password,
          passwordNew: data.passwordNew,
          passwordNew_confirmation: data.passwordNew_confirmation,
        },
      }).then(() => {
        this.fetchUser();
      });
    },
    changeUserEmail(email = '') {
      return useRequest('/user/email', {
        method: 'POST',
        body: {
          email,
        },
      }).then(() => {
        this.fetchUser();
      });
    },
    changeEmailVerify() {
      return useRequest('/user/email/verify', {
        method: 'POST',
      }).then(() => {
        this.fetchUser();
      });
    },
    setCookieState(data) {
      this.isSelectedCookie = Boolean(data);
    },
    initAdult() {
      const adultCookie = useCookie('adult');

      if (adultCookie.value) {
        this.setAdult(adultCookie.value);
      }
    },
    setAdult(data) {
      this.isAdult = data;
    },
    changeUserRegion(region = '') {
      return useRequest('/user/region', {
        method: 'POST',
        body: {
          region,
        },
      }).then(() => {
        this.fetchUser();
      });
    },
  },
});
