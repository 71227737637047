import { addMethod, mixed, setLocale, string } from 'yup';
import { parse, isValid } from 'date-fns';

import useFile from '@/composables/useFile';
import { defineNuxtPlugin } from 'nuxt/app';

const fileComposable = useFile();

export default defineNuxtPlugin(() => {
  setLocale({
    mixed: {
      default: 'Поле содержит ошибку',
      required: 'Поле обязательно для заполнения',
      oneOf: 'Поле должно содержать одно из следующих значение: ${values}',
      notOneOf:
        'Поле не должно содержать одно из следующих значение: ${values}',
      notNull: 'Поле не должно быть пустым',
    },
    string: {
      length: 'Поле должно иметь длину ${length} символов',
      min: 'Поле должно содержать минимум ${min} символов',
      max: 'Поле должно содержать не более ${max} символов',
      matches:
        'Поле должно совпадать со следующим регулярном выражением: „${regex}”',
      email: 'Поле должно быть электронной почтой',
      url: 'Поле должно быть валидной ссылкой',
      trim: 'Поле не должно содержать в начале или в конце пробелы',
      lowercase: 'Поле должно быть в нижним регистре',
      uppercase: 'Поле должно быть в верхнем регистре',
    },
    number: {
      min: 'Поле должно быть больше или равно ${min}',
      max: 'Поле должно быть меньше или равно ${max}',
      lessThan: 'Поле должно быть меньше чем ${less}',
      moreThan: 'Поле должно быть больше ${more}',
      positive: 'Поле должно быть положительном числом',
      negative: 'Поле должно быть негативном числом',
      integer: 'Поле должно быть целым числом',
    },
    date: {
      min: 'Поле не может быть меньше начальной ${min}',
      max: 'Поле не может быть больше конечной ${max}',
    },
    boolean: {
      isValue: 'Поле обязательно должно быть выбрано',
    },
    object: {
      noUnknown: 'Поле не может содержать неизвестные ключи',
    },
    array: {
      min: 'Поле должно быть указано не менее ${min} элементов',
      max: 'Поле должно быть указано не более ${max} элементов',
    },
  });

  addMethod(string, 'codeConfirmation', function (length) {
    return this.test(
      'isCodeConfirmation',
      'Поля обязательны для заполнения',
      async (value) => {
        if (!value) return false;

        return value.length === length;
      },
    );
  });

  addMethod(string, 'onlyCyrillic', function () {
    return this.test(
      'isOnlyCyrillic',
      'Поле допускает использование только кириллицы',
      async (value) => {
        if (!value) return true;

        return /^[а-яёА-ЯЁ]+([а-яёА-ЯЁ ,.'-]+)?[а-яёА-ЯЁ]*$/i.test(value);
      },
    );
  });

  addMethod(string, 'authPassword', function () {
    return this.test(
      'isAuthPassword',
      'Проверьте правильно ли вы ввели',
      async (value) => {
        if (!value) return true;

        // Проверяет:
        // Хотя бы одну маленькую букву [a-z].
        // Хотя бы одну большую букву [A-Z].
        // Хотя бы одну цифру или специальный символ [\d!@#$%^&*()_+[\]{}|;:'",.<>?/].
        // Длину строки не менее 8 символов {8,}.

        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d!@#$%^&*()_+[\]{}|;:'",.<>?/]).{8,}$/g.test(
          value,
        );
      },
    );
  });

  addMethod(mixed, 'fileType', function (allowedTypes) {
    if (!allowedTypes) return true;

    return this.test('isValidType', 'Неверный формат файла', async (file) => {
      if (!file) return true;

      const arrayBuffer = await fileComposable.readFileAsync(file);
      const fileType = fileComposable.getFileType(arrayBuffer);

      return allowedTypes.includes(fileType);
    });
  });

  addMethod(mixed, 'fileListTypes', function (allowedTypes) {
    if (!allowedTypes) return true;

    return this.test(
      'isValidListTypes',
      'Неверный формат файла',
      async (files) => {
        if (!files) return true;

        const arrayBuffers = [];
        const promiseArray = [];

        files.forEach((item) => {
          promiseArray.push(
            fileComposable.readFileAsync(item).then((result) => {
              arrayBuffers.push(result);
            }),
          );
        });

        await Promise.all(promiseArray);

        const fileTypes = arrayBuffers.map((item) => {
          return fileComposable.getFileType(item);
        });

        return fileTypes.every((item) => allowedTypes.includes(item));
      },
    );
  });

  addMethod(mixed, 'maxFileSize', function (maxBitesSize) {
    if (!maxBitesSize) return true;

    const paramsSize = parseInt(maxBitesSize, 10);
    const convertedValue = fileComposable.getMbFromKb(paramsSize);

    const stringValue = convertedValue.toString().split(',')[0];

    return this.test(
      'isValidSize',
      `Загрузите файл размером не больше ${stringValue} Мб`,
      (file) => {
        if (!file) return true;

        return maxBitesSize > file.size;
      },
    );
  });

  addMethod(mixed, 'maxFileListSize', function (maxBitesSize) {
    if (!maxBitesSize) return true;

    const paramsSize = parseInt(maxBitesSize, 10);

    const convertedValue = fileComposable.getMbFromKb(paramsSize);
    const stringValue = convertedValue.toString().split(',')[0];

    return this.test(
      'isValidListSize',
      `Загрузите файлы размером не больше ${stringValue} Мб`,
      (files) => {
        if (!files) return true;

        const totalSize = files.reduce((acc, item) => {
          return acc + item.size;
        }, 0);

        return totalSize < paramsSize;
      },
    );
  });

  addMethod(mixed, 'minFileListSizeKb', function (minKbSize) {
    if (!minKbSize) return true;

    return this.test(
      'isValidListMinSizeKb',
      `Загрузите файлы размером не менее ${minKbSize} кБ`,
      (files) => {
        if (!files) return true;

        const isSizes = files.map(
          (item) => fileComposable.getBitesFromKb(minKbSize) < item.size,
        );

        return isSizes.every((value) => value === true);
      },
    );
  });

  addMethod(mixed, 'maxFileLength', function (length) {
    if (!length) return true;

    return this.test(
      'isValidLength',
      `Файлов должно быть не больше ${length}`,
      (file) => {
        if (!file) return true;

        return Array.from(file).length <= length;
      },
    );
  });

  addMethod(string, 'lengthPhone', function (length) {
    return this.test(
      'isLengthPhone',
      `Должно быть ${length} символов`,
      async (value) => {
        if (!value) return false;

        // Преобразование phoneValue в строку и удаление всех нечисловых символов
        const phone = value.toString().replace(/\D/g, '');

        return phone.length === length;
      },
    );
  });

  addMethod(string, 'isValidEmail', function () {
    return this.test(
      'isValidEmail',
      'Поле должно быть электронной почтой',
      function (value) {
        if (!value) return false;

        // Простая регулярка для проверки формата email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        return emailRegex.test(value);
      },
    );
  });

  addMethod(string, 'isValidBirthDate', function () {
    return this.test(
      'isValidBirthDate',
      'Укажите корректную дату рождения',
      function (value) {
        if (!value) return false;

        // Парсим строку даты в формате ДД.ММ.ГГГГ
        const parsedDate = parse(value, 'dd.MM.yyyy', new Date());

        // Проверяем, является ли дата валидной
        return isValid(parsedDate);
      },
    );
  });

  addMethod(string, 'maxStringLength', function (length) {
    return this.test(
      'isMaxStringLength',
      `Должно быть не больше ${length} символов`,
      async (value) => {
        if (!value) return true;

        return value.length <= length;
      },
    );
  });

  addMethod(string, 'isValidCigaretteCountPerDay', function () {
    return this.test(
      'isValidCigaretteCountPerDay',
      `Должно быть число от 1 до 300`,
      async (value) => {
        // Если значение пустое, пропускаем проверку
        if (!value) return true;

        // Проверка на то, что значение является числом и находится в пределах от 1 до 300
        const number = Number(value);

        if (number <= 0 || number > 300) return false;

        // Проверка на отсутствие ведущих нулей (например 001)
        return String(number) === value;
      },
    );
  });
});
