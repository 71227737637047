import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formValidation_BRtR4dZ3KO from "/app/plugins/formValidation.js";
import initial_data_lKRJEMtQLN from "/app/plugins/initial-data.js";
import metrics_client_1j6AwyuWYQ from "/app/plugins/metrics.client.js";
import sentry_client_GoGQuZo4Et from "/app/plugins/sentry.client.js";
import tracker_mind_box_BhoaBIsFD3 from "/app/plugins/tracker-mind-box.js";
import typograph_5DxQj8Q51f from "/app/plugins/typograph.js";
import vClickOutsider_8dZMCqBqL7 from "/app/plugins/vClickOutsider.js";
import vue_final_modal_6X7LqUlyAl from "/app/plugins/vue-final-modal.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  formValidation_BRtR4dZ3KO,
  initial_data_lKRJEMtQLN,
  metrics_client_1j6AwyuWYQ,
  sentry_client_GoGQuZo4Et,
  tracker_mind_box_BhoaBIsFD3,
  typograph_5DxQj8Q51f,
  vClickOutsider_8dZMCqBqL7,
  vue_final_modal_6X7LqUlyAl
]