<script setup>
import { usePolicePopup } from '@/composables/usePolicePopup';

import { police } from '@/configs/police';

const { openPolicePopup } = usePolicePopup();
</script>

<template>
  <div class="police-block">
    <button
      type="button"
      class="police-block__button"
      @click="openPolicePopup(police.termsConditions)"
    >
      <span class="police-block__font"> Пользовательское соглашение </span>
    </button>

    <span class="police-block__font hidden-mobile">|</span>

    <button
      type="button"
      class="police-block__button"
      @click="openPolicePopup(police.privacyPolicy)"
    >
      <span class="police-block__font"> Политика о конфиденциальности </span>
    </button>
  </div>
</template>

<style scoped lang="scss">
.police-block {
  display: flex;
  align-items: center;
  gap: em(16);
  color: $color-white;

  @include media-breakpoint-down(sm) {
    gap: em(8);
  }

  &__font {
    font-size: em(13);
    font-weight: 700;
    line-height: 1.2;
  }

  &__button {
    transition: $time-default ease-out;
    transition-property: color;

    @include hover {
      color: $color-blue-1;
    }
  }
}
</style>
