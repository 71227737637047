<script setup>
import { useNuxtApp } from '#imports';

import AuthLayout from '@/components/layout/AuthLayout.vue';
import UiButton from '@/components/ui/button/UiButton.vue';

const { $tp } = useNuxtApp();

defineProps({
  error: {
    type: Object,
    required: true,
  },
});
</script>

<template>
  <AuthLayout id="app" class="error">
    <template #content>
      <div class="error__content">
        <div class="error__heading">
          <p class="error__font error__font--heading">
            {{ $tp('Такой страницы не существует') }}
          </p>
        </div>

        <div class="error__description">
          <p class="error__font error__font--description">
            {{
              $tp(
                'Мы не смогли найти страницу, которую вы искали. Возможно, она была перемещена или больше не существует.',
              )
            }}
          </p>
        </div>

        <UiButton to="/" text="Перейти на главную" class="error__button" />
      </div>
    </template>
  </AuthLayout>
</template>

<style scoped lang="scss">
.error {
  &__font {
    &--heading {
      font-size: em(60);
      font-weight: 600;
      line-height: 1;
      letter-spacing: -0.03em;

      @include media-breakpoint-down(sm) {
        font-size: em(30);
      }
    }

    &--description {
      font-size: em(18);
      line-height: 1.3;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: em(36);
    width: em(800);
    padding: em(64) em(96);
    margin: auto;
    text-align: center;
    background-color: $color-white;
    border-radius: em(48);

    @include media-breakpoint-down(sm) {
      gap: em(24);
      width: 100%;
      padding: em(32) em(24);
    }
  }

  &__button {
    width: 100%;
  }
}
</style>
