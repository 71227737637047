<template>
  <div id="app" class="app">
    <NuxtLayout v-slot="{ className }" class="app__layout">
      <NuxtPage :class="className" />
    </NuxtLayout>

    <ModalsContainer />
  </div>
</template>

<script setup>
import { watch, useRouter } from '#imports';
import { ModalsContainer } from 'vue-final-modal';
import { useJwt } from '@vueuse/integrations/useJwt';

import { useAuthStore } from '@/stores/auth';

import { useSessionEnded } from '@/composables/useSessionEnded';

const authStore = useAuthStore();
const router = useRouter();

//<editor-fold desc="CHECK TOKEN">
let intervalCheckToken = null;
watch(
  () => authStore.accessToken,
  (value) => {
    if (intervalCheckToken) {
      clearInterval(intervalCheckToken);
      intervalCheckToken = null;
    }

    if (process.server || !value) return;

    intervalCheckToken = setInterval(() => {
      checkTokenExpiration();
    }, 30000);
  },
  {
    immediate: true,
  },
);
async function checkTokenExpiration() {
  const bufferTime = 5 * 60; // 5 минут в секундах
  const { payload } = useJwt(authStore.accessToken);
  const expTime = payload.value.exp;
  let currentTime = Math.floor(Date.now() / 1000);

  try {
    const serverTime = await authStore.fetchServerTime();

    currentTime = serverTime.time;
  } catch (e) {
    console.log(e);
  }

  const timeLeft = expTime - currentTime;

  if (timeLeft <= bufferTime) {
    if (timeLeft > 0) {
      authStore.refreshToken();
    } else {
      authStore.logout();

      const { openSessionEndedPopup } = useSessionEnded();

      openSessionEndedPopup({
        heading: 'Сеанс завершен',
        description:
          'Для продолжения работы с сайтом, пожалуйста, авторизуйтесь заново',
        buttonsData: [
          { text: 'Продолжить', action: (closeModal) => closeModal?.() },
        ],
      });

      router.push('/auth/login');
    }
  }
}
//</editor-fold>
</script>

<style scoped lang="scss">
.app {
  $parent: &;

  display: flex;
  flex-direction: column;

  &:deep(#{$parent}__layout) {
    flex-grow: 1;
  }
}
</style>
