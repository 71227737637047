import { useRuntimeConfig, useRouter } from '#imports';

import { useAuthStore } from '@/stores/auth';

import { useSessionEnded } from '@/composables/useSessionEnded';

import { API_PREFIX, API_VERSIONS } from '@/constants/variables';

let fetchInstance;

/**
 * Fetch data from backend api
 * @description Use it in store actions
 * @description Use store actions in useAsyncData
 * @description Provide SERVER_URL and CLIENT_URL env variables
 * @param {string} url request url after api prefix
 * @param {object} [options] request options (query, body, headers etc.)
 * @param {string} [apiVersion] api url version prefix e.g. 'v1'
 * @returns {Promise<any>} request promise
 * @example
 * useRequest('/route').then((data) => console.log(data));
 * @see https://nuxt.com/docs/api/utils/dollarfetch
 * @see https://nuxt.com/docs/api/composables/use-async-data
 * @see https://pinia.vuejs.org/ssr/nuxt.html
 */
export const useRequest = (url, options = {}, apiVersion = API_VERSIONS.v1) => {
  // creating instance only if it doesn't exist
  if (!fetchInstance) {
    const {
      app: { baseURL },
      public: { clientUrl },
    } = useRuntimeConfig();

    // configuring url
    let baseUrl = baseURL === '/' ? '' : baseURL;

    if (import.meta.server) {
      // server url
      const { serverUrl } = useRuntimeConfig();
      baseUrl = serverUrl;
    } else if (!import.meta.dev) {
      // client url only on prod (proxy for dev)
      baseUrl = clientUrl;
    }

    fetchInstance = $fetch.create({
      baseURL: `${baseUrl}/${API_PREFIX}`,
      onRequest({ options }) {
        const authStore = useAuthStore();

        options.headers = {
          ...options.headers,

          Accept: 'application/json',
          Authorization: `Bearer ${authStore.accessToken}`,
        };
      },
      onResponseError({ response }) {
        const exceptions = ['/auth/login/phone', '/auth/register/step/status'];
        const responseUrl = response?.url;
        const isException = exceptions.some((exception) =>
          responseUrl.includes(exception),
        );

        if (response.status === 401 && !isException) {
          const authStore = useAuthStore();

          authStore.logout();

          const { openSessionEndedPopup } = useSessionEnded();
          const router = useRouter();

          openSessionEndedPopup({
            heading: 'Сеанс завершен',
            description:
              'Для продолжения работы с сайтом, пожалуйста, авторизуйтесь заново',
            buttonsData: [
              { text: 'Продолжить', action: (closeModal) => closeModal?.() },
            ],
          });

          router.push('/auth/login');
        }
      },
    });
  }

  return fetchInstance(`/${apiVersion}${url}`, options);
};
